<template>
  <v-main>
    <v-header
      :headerImage="image"
      :displayOverlay="true"
      :overlayCopy="overlayCopy"
    />
    <container type="bg-dark" id="start">
      <v-row>
        <v-col v-for="(item, id) in project" :cols="12" :md="6" :key="id">
          <v-card
            tile
            dark
            elevation="0"
            class="bg-transparent white--text mb-3"
            :to="{ name: 'project', params: { id: id, name: item.slug } }"
          >
            <v-img :src="item.image" :aspect-ratio="16 / 9" />
            <v-card-text class="pt-5 pb-0 text-uppercase">{{
              item.category.join(" and ")
            }}</v-card-text>
            <v-card-title class="font-weight-bold pt-1">
              <span v-for="(item, id) in item.title" :key="id"
                >{{ item.copy }}&nbsp;
              </span>
            </v-card-title>
            <v-card-text v-html="item.introText" />
          </v-card>
        </v-col>
      </v-row>
    </container>
  </v-main>
</template>

<script>
import vHeader from "@/layout/includes/Header.vue";
import { projects } from "@/data/projects.js";

export default {
  name: "Work",
  components: {
    vHeader,
  },
  data() {
    return {
      image: require("@/assets/backgrounds/work_header.jpg"),
      overlayCopy: [
        {
          copy: "Our Work",
          size: "50",
        },
      ],
      project: projects,
    };
  },
};
</script>
